import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useDispatch, Provider } from 'react-redux'
import { Route, Router, } from 'preact-router';
import LogRocket from 'logrocket';

import createWs from './ws';
import store, {  wsState } from './store'

import Nav from './components/nav';

import Main from './routes/main';
import Acp from './routes/acp';
import Login from './routes/login';
import Search from './routes/search';
import List from './routes/list';
import Info from './routes/info';

if (window.location.host === 'matescrates.au') LogRocket.init('yh0dy3/mcrates');

const Mcrates = () => {
    const dispatch = useDispatch();

    // i have no idea why this is needed,
    // but it makes sure the user changes actually rerender
    useEffect(() => {
        const ws = createWs(dispatch);
        ws.connect();
        dispatch(wsState.actions.set(ws));
    }, [dispatch])

    function routeChange(e) {
        const { ws } = store.getState();
        if (!ws) return null;
        if (e.url === '/acp') return ws.sendAcpOffers();
        if (e.url === '/') return ws.sendOffers();
        if (e.url === '/list') return ws.sendWants();
    }

    return (
        <div id="mcrates">
            <Nav />
            <Router onChange={routeChange}>
                <Route path="/" component={Main} />
                <Route path="/login" component={Login} mode="login" />
                <Route path="/register" component={Login} mode="register" />
                <Route path="/search" component={Search} />
                <Route path="/list" component={List} />
                <Route path="/info" component={Info} />

                {/* ACP */}
                <Route path="/acp" component={Acp} />
            </Router>
        </div>
    );
};

const App = () => {
    return (
        <Provider store={store}>
            <Mcrates />
        </Provider>
    );
}

export default App;
